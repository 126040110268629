import React from "react";
import { Link, navigate } from "gatsby";
import Pagination from "@material-ui/lab/Pagination";

const Pager = ({ pageContext }) => {
  const {
    numberOfPages,
    humanPageNumber,
    previousPagePath,
    nextPagePath,
  } = pageContext;
  // const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    // setPage(value);
    if (value === 1) {
      navigate("/posts");
    } else {
      navigate(`/posts/${value}`);
    }
  };

  return (
    <nav style={{ display: "flex", justifyContent: "space-between" }}>
      {/* <div>
        {previousPagePath && (
          <Link to={previousPagePath}>
            <button>← Newer Posts</button>
          </Link>
        )}
      </div> */}
      <Pagination
        page={humanPageNumber}
        onChange={handleChange}
        count={numberOfPages}
      />
      {/* <div style={{ justifySelf: "flex-end" }}>
        {nextPagePath && (
          <Link to={nextPagePath}>
            <button>Older Posts →</button>
          </Link>
        )}
      </div> */}
    </nav>
  );
};

export default Pager;
// Pager.PropTypes = {
//   pageContext: PropTypes.object.isRequired,
// };
// export default Pager;
