import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../index.css";
import Pager from "../components/pager";
import Pagination from "@material-ui/lab/Pagination";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";

/**
Gatsby-awesome-pagination useful resources
https://www.digitalocean.com/community/tutorials/gatsbyjs-pagination-in-gatsby
https://www.youtube.com/watch?v=KJyxJYKLm6g
 */

const BlogArchive = ({ data, pageContext, location }) => {
  const posts = data.allMdx.nodes;
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1400 });
    return isDesktop ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1400 });
    return isMobile ? children : null;
  };

  return (
    <div className="index-main">
      <Layout location={location}>
        <SEO title="All posts" />
        <Desktop>
          <div className="main-post-container">
            <h5 className="latest-articles-heading">Latest Articles</h5>

            <ol className="post-ordered-list" style={{ listStyle: `none` }}>
              {posts.map((post, i) => {
                const image = getImage(post.frontmatter.thumbnail);
                const title = post.frontmatter.title || post.fields.slug;

                return (
                  <li className="post-flex-list-index" key={post.fields.slug}>
                    <div className="post-thumbnail">
                      <a
                        className={`category-badge ${post.frontmatter.categories}`}>
                        <span>{_.upperCase(post.frontmatter.categories)}</span>
                      </a>
                      <GatsbyImage
                        imgStyle={{
                          "object-fit": "contain",
                          opacity: "1",
                        }}
                        // className="index-thumbnail"
                        image={image}
                        alt={post.frontmatter.description}
                      />
                    </div>
                    <div className="post-content">
                      <article
                        className="post-list-item"
                        itemScope
                        itemType="http://schema.org/Article">
                        <header>
                          <h2>
                            <Link to={post.fields.slug} itemProp="url">
                              <span
                                className="post-heading"
                                itemProp="headline">
                                {title}
                              </span>
                            </Link>
                          </h2>
                          <small>{post.frontmatter.date}</small>
                        </header>
                        <section>
                          <p
                            className="description"
                            dangerouslySetInnerHTML={{
                              __html:
                                post.frontmatter.description || post.excerpt,
                            }}
                            itemProp="description"
                          />
                        </section>
                      </article>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
          <Pager pageContext={pageContext} />
        </Desktop>
        <Mobile>
          <div className="mobile-main-container">
            <h5 className="latest-articles-heading">Latest Articles</h5>

            <ol className="post-ordered-list" style={{ listStyle: `none` }}>
              {posts.map((post, i) => {
                const image = getImage(post.frontmatter.thumbnail);
                const title = post.frontmatter.title || post.fields.slug;

                return (
                  <li
                    className="mobile-post-flex-list-index"
                    key={post.fields.slug}>
                    <div className="post-thumbnail">
                      <a
                        className={`category-badge ${post.frontmatter.categories}`}>
                        <span>{_.upperCase(post.frontmatter.categories)}</span>
                      </a>
                      <GatsbyImage
                        imgStyle={{
                          "object-fit": "contain",
                          opacity: "1",
                        }}
                        // className="index-thumbnail"
                        image={image}
                        alt={post.frontmatter.description}
                      />
                    </div>
                    <div className="mobile-post-content">
                      <article
                        className="post-list-item"
                        itemScope
                        itemType="http://schema.org/Article">
                        <header>
                          <h2>
                            <Link to={post.fields.slug} itemProp="url">
                              <span
                                className="post-heading"
                                itemProp="headline">
                                {title}
                              </span>
                            </Link>
                          </h2>
                          <small>{post.frontmatter.date}</small>
                        </header>
                        <section>
                          <p
                            className="description"
                            dangerouslySetInnerHTML={{
                              __html:
                                post.frontmatter.description || post.excerpt,
                            }}
                            itemProp="description"
                          />
                        </section>
                      </article>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
          <Pager pageContext={pageContext} />
        </Mobile>
      </Layout>
    </div>
  );
};

export default BlogArchive;


export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          categories
          tags
          thumbnail {
            id
            absolutePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
